import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import wetsuitFinderStyle from './wetsuitFinderStyle'
import Footer from '../../components/Footer/Footer'
import {graphql} from 'gatsby'

import WetsuitFinderContent from './WetsuitFinderContent'

import HeaderProvider from '../../components/Header/HeaderProvider'
import PageHeaderFinder from '../../components/PageHeaderFinder/PageHeaderFinder'
import TriggerSearchBar from "./TriggerSearchBar";
import InstagramSection from "../../components/InstagramSection/InstagramSection";
import SpainHolidaysCta from "../../elements/SpainholidaysCta/SpainHolidaysCta";
import SEO from '../../components/SEO'


const WetsuitFinder = props => {

    const {classes, data} = props
  const modified = []

  data.datoCmsWetsuitFinder._allUrlLocales.map(item => {
    if (item.locale === 'de') {
      modified.push({ 'locale': item.locale, 'value': item.value })
    } else {
      modified.push({ 'locale': item.locale, 'value': item.value })
    }
  })
    return (
        <div className={classes.backgroundColor}>
            <SEO
              seoMetaTags={data.datoCmsWetsuitFinder.seoMetaTags.tags}
              actLang={props.pageContext.locale}
              localURLS={modified}
            />
            <HeaderProvider
                localURLS={data.datoCmsWetsuitFinder._allUrlLocales}
                actLang={props.pageContext.locale}
                SEOtitle={data.datoCmsWetsuitFinder.pageTitle}
                SEOdescription={data.datoCmsWetsuitFinder.pageSubtitle}
                SEOimage={data.datoCmsWetsuitFinder.headerImage.fluid.src}
                light
                absolute
                compact
            />
            <PageHeaderFinder
                leftHeader
                image={data.datoCmsWetsuitFinder.headerImage.fluid}
                title={data.datoCmsWetsuitFinder.pageTitle}
                subtitle={data.datoCmsWetsuitFinder.pageSubtitle}
                alt={data.datoCmsWetsuitFinder.headerImage.alt}
                actLang={props.pageContext.locale}
                transl={data.datoCmsTranslationMain}

                onSBClick={() => TriggerSearchBar()}
            />

            <WetsuitFinderContent
                transl={data.datoCmsTranslationMain}
                actLang={props.pageContext.locale}
                onSBClick={() => TriggerSearchBar()}
            />
            <InstagramSection/>
            <SpainHolidaysCta
                title={data.datoCmsHome.bannerBlank[0].title}
                subtitle={data.datoCmsHome.bannerBlank[0].subtitle}
                buttonTitle={data.datoCmsHome.bannerBlank[0].buttonTitle}
                buttonLink={data.datoCmsHome.bannerBlank[0].buttonLink}
            />
            <Footer
                actLang={props.pageContext.locale}
            />
        </div>
    )

}

export default withStyles(wetsuitFinderStyle)(WetsuitFinder)

export const query = graphql`
    query($locale: String!) {
        datoCmsWetsuitFinder(locale:{eq:$locale}) {
            seoMetaTags{
                tags
            }
            _allUrlLocales{locale value}
            pageTitle
            pageSubtitle
            headerImage{
                alt
                fluid(maxWidth:1800){
                    src
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }

        }
        datoCmsTranslationMain (locale:{eq:$locale}){
            metricDropDownText
            mobileSearchMenubuttonLabel
            searchButtonTitle
            searchSectionBrandsTitle
            searchSectionDimensionsChest
            searchSectionDimensionsHeight
            searchSectionDimensionsTitle
            searchSectionDimensionsHips
            searchSectionDimensionsWaist
            searchSectionDimensionsWeight
            searchSectionGenderKids
            sortProductsItemNewproducts
            sortProductsItemLowestprice
            sortProductsItemHeighestprice
            sortProductsDropdownTitle
            showMoreButtonLabel
            searchSectionZiptypeTitle
            searchSectionZiptypeNozip
            searchSectionZiptypeFrontzip
            searchSectionZiptypeBackzip
            searchSectionTemperatureWinter
            searchSectionTemperatureTropics
            searchSectionTemperatureTitle
            searchSectionTemperatureSummer
            searchSectionTemperatureAutumn
            searchSectionTemperatureAll
            searchSectionPriceTitle
            searchSectionGenderWomen
            searchSectionGenderTitle
            searchSectionGenderMen
            countryDropdownText
        }
        datoCmsHome(locale:{eq:$locale}) {



            bannerBlank{
                title
                subtitle
                buttonLink
                buttonTitle
            }
        }
    }
`